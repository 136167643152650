import React from "react";
import "./Header.css"; // Make sure to include the CSS file
import logo from "../../Assets/logo.png";

const Header = () => {
  return (
    <header className="header">
      <div className="logo">
        <img src={logo} className="logo" alt="FullAI- Shaping the futue"></img>
      </div>
      <button className="login-button">
        <a href="https://blogbook.digihut.store/" className="hrefbutton">
          Login
        </a>
      </button>
    </header>
  );
};

export default Header;
