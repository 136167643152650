import React, { useState } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import config from "../config";
import toast, { Toaster } from "react-hot-toast";

import Spinner from "../Components/Loading/Spinner";
import fullai from "../Assets/fullai.png";
import "./TextGenerator.css";

const TextGenerator = () => {
  const [prompt, setPrompt] = useState("");
  const [generatedText, setGeneratedText] = useState("");
  const [loading, setLoading] = useState(false);

  const API = config.API_KEY;

  const sendicon = "https://cdn-icons-png.flaticon.com/128/10486/10486739.png";

  const handleGenerateText = async () => {
    console.log("This ipi key from env", API);
    setLoading(true);
    setGeneratedText(""); // Clear previous results
    try {
      // const response = await fetch("http://localhost:5000/generate-text", {
      const response = await fetch(
        "https://fullaiserverbackend.digihut.store/generate-text",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // "api-key": API, // Use environment variable here
          },

          body: JSON.stringify({ prompt }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setGeneratedText(data.text);
    } catch (error) {
      console.error("Error generating text:", error);
    } finally {
      setLoading(false);
      setPrompt(""); // Clear the input field after submission
    }
  };

  //====================
  //conditions
  //=====================

  const handleCopyText = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // Show a success toast notification
        alert("Text copied to clipboard!");
        toast.success("Text copied to clipboard!");
      })
      .catch((err) => {
        // Show an error toast notification
        toast.error("Failed to copy text.");
        console.error("Failed to copy text: ", err);
      });
  };

  const renderContent = (content) => {
    if (!content) {
      // Return a placeholder div if content is empty
      return (
        <div className="placeholder">
          <img src={fullai} alt="full ai " className="fullaiimage"></img>
          <div className="box">
            <p1>By</p1>
            <h2>Muhammad Asad Nazir</h2>
          </div>
        </div>
      );
    }

    const regex = /```([\s\S]*?)```/g;
    const parts = content.split(regex);

    return parts.map((part, index) => {
      if (index % 2 === 1) {
        // This is code block
        return (
          <div key={index} className="codeBlockContainer">
            <button className="copyButton" onClick={() => handleCopyText(part)}>
              <img
                src="https://cdn-icons-png.flaticon.com/128/9411/9411848.png"
                alt="copy all text"
                className="copy-icon"
              ></img>
            </button>
            <SyntaxHighlighter language="javascript" style={materialDark}>
              {part}
            </SyntaxHighlighter>
          </div>
        );
      }
      // This is normal text
      return <p key={index}>{part}</p>;
    });
  };

  return (
    <div className="container">
      <div className="generatedTextContainer">
        <div className="generatedText">
          {loading ? (
            <Spinner />
          ) : (
            <>
              {renderContent(generatedText)}
              {generatedText && (
                <button
                  className="copyButton"
                  onClick={() => handleCopyText(generatedText)}
                >
                  <img
                    src="https://cdn-icons-png.flaticon.com/128/9411/9411848.png"
                    alt="copy all text"
                    className="copy-icon"
                  ></img>
                </button>
              )}
            </>
          )}
        </div>
      </div>
      <div className="inputContainer">
        <textarea
          className="textarea"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          placeholder="Write something "
        />
        <button
          className="button"
          onClick={handleGenerateText}
          disabled={loading} // Disable button while loading
        >
          {loading ? (
            <span className="spinner"></span> // Add a spinner or loading indicator
          ) : (
            <img src={sendicon} alt="generate" className="submit-button" />
          )}
        </button>
      </div>
    </div>
  );
};

export default TextGenerator;
